<template>
  <Masonry :value="value" class="custom-masonry" />
</template>

<script>
import Masonry from '@kvass/template-gallery/Types/Masonry'

export default {
  props: {
    data: Object,
  },

  computed: {
    value() {
      return this.$path('content', this.data) || []
    },
  },

  components: {
    Masonry,
  },
}
</script>

<style lang="scss">
.custom-masonry {
  .masonry {
    &__content {
      gap: 1rem;
      @include respond-above('phone') {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    &__item {
      @include respond-above('phone') {
        &:nth-child(even) {
          grid-column-end: span 2;
        }
        &:nth-child(2) {
          grid-column-end: initial;
        }
      }
      overflow: hidden;

      img {
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
</style>
