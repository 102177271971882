<template>
  <div class="openhouse">
    <h2 class="openhouse__title">{{ $tc('openhouse', 2) }}</h2>
    <div class="openhouse__list" :class="{ 'openhouse__list--scroll': items.length > 4 }">
      <div class="openhouse__item" v-for="item in items" :key="item.id">
        <FontAwesomeIcon :icon="['fal', 'calendar']" size="lg" />
        <div>
          <div class="openhouse__content row wrap">
            <div class="openhouse__date">{{ item.from | Moment('dddd D. MMMM') }}</div>
            <div class="openhouse__time">
              {{ $t('clockAt') }} {{ item.from | Moment('HH.mm') }} -
              {{ item.to | Moment('HH.mm') }}
            </div>
          </div>
          <div v-if="$path('references.length', item)" class="openhouse__references">
            {{
              $t('openhouseAtValue', {
                value: `${$tc(
                  'residential',
                  item.references.length,
                ).toLowerCase()} ${item.references.map(r => r.number).join(', ')}`,
              })
            }}
          </div>
          <ReadMore v-if="item.message" :value="item.message" class="openhouse__message" />
        </div>
      </div>
      <div v-if="!items || !items.length" class="openhouse__fallback openhouse__item">
        {{ $t('openhouseOnRequest') }}
      </div>
    </div>
  </div>
</template>

<script>
import { Moment } from '@/filters'
import ReadMore from '@/components/ReadMore'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    items() {
      return this.data.content || []
    },
  },
  filters: {
    Moment,
  },
  components: {
    ReadMore,
  },
}
</script>

<style lang="scss">
.openhouse {
  color: $dark;
  &__title {
    margin-bottom: 0.8rem;
  }

  &__list {
    &--scroll {
      max-height: 320px;
      overflow-y: scroll;
      @include scrollShadow();
    }

    display: grid;
    grid-gap: 1rem;
  }

  &__item {
    padding: 1.5rem;
    border: 1px solid $border-color;

    border-radius: $border-radius;

    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
    }
  }

  &__content {
    @include gap(2px);
  }

  &__date {
    font-weight: bold;
  }

  &__time {
    opacity: 0.6;
  }

  &__references {
    opacity: 0.7;
  }

  &__message,
  &__references {
    font-size: 0.9em;
    margin-top: 2px;
  }
}
</style>
