import { getValueFromArray } from '@/utils'
import Cta from './partials/cta'

export default function() {
  const get = path => this.$path(path, this.$store.state)

  function getCoverText(title, content) {
    if (title) return `<h1>${title}</h1> ${content}`

    return content
  }

  function getLocation(type) {
    if (type === 'flatfinder') {
      let areas = (get('Project.item.posts') || []).find(
        post => (post.customFieldsConfig[0] || {}).source === 'areas',
      )

      if (areas) {
        return `/${areas.slug}#section-${type}`
      }
    }

    return `#${type}`
  }

  const showStats =
    get('Project.item.customFields.key-value.0.title') ||
    get('Project.item.customFields.key-value.0.value')

  function getLinkGridItems() {
    const posts = (get('Root.item.posts') || [])
      .filter(post => !post.customFields?.['page-settings']?.['hide-from-link-grid'])
      .map((post, index) => ({
        text: post.title,
        url: post.slug,
        image: post.media?.cover[0],
        order: Number(post.customFields?.['page-settings']?.['menu-order']) || index + 1,
      }))
    const customFields = (get('Project.item.customFields.link-cards') || [])
      .filter(c => c.image && c.url)
      .map((c, index) => ({
        ...c,
        order: Number(c.order) || index + 1,
      }))
      .sort((a, b) => a.order - b.order)

    const flatfinder = get('Project.item.customFields.flatfinder-external.url')
      ? {
          text:
            get('Project.item.customFields.title-overrides.flatfinder') ||
            this?.$tc('unitSelector', 1),
          url: get('Project.item.customFields.flatfinder-external.url'),
          image: get('Project.item.customFields.flatfinder-external.image'),
          order: 0,
        }
      : get('Project.item.flatfinders.length')
      ? [get('Project.item.flatfinders').find((f, i) => f.isEntry || i === 0)].map(f => ({
          text:
            get('Project.item.customFields.title-overrides.flatfinder') ||
            this?.$tc('unitSelector', 1),
          url: '/enhetsvelger',
          image: f.image,
          order: 0,
        }))[0]
      : null

    return [flatfinder, ...posts, ...customFields].filter(Boolean).sort((a, b) => a.order - b.order)
  }

  return [
    {
      component: 'Section',
      condition: get('Project.item.media.cover'),
      props: {
        width: 'full',
        id: 'section-hero',
        class: 'project-hero-section',
        style: 'padding: 0;',
        theme: !get('Project.item.description')
          ? get('Project.item.customFields.additional-sections.0.theme') || 'white'
          : 'white',
      },
      blocks: {
        default: {
          component: 'Slider',
          data: get('Project.item.media.cover'),
          props: {
            class: `hero__slider ${showStats ? 'section-hero' : ''}`,
            aspectRatio: '16/9',
            style: 'position: relative',
            options: {
              manualNavigation: false,
            },
          },
          blocks: {
            default: [
              {
                component: 'Flex',
                condition:
                  get('Project.item.customFields.project-hero.title') ||
                  get('Project.item.customFields.project-hero.content') ||
                  (get('Project.item.customFields.project-hero-buttons') || []).filter(f => {
                    return f.content && f.type
                  }).length,
                props: {
                  class: `hero__text hero__text-placement--${get(
                    'Project.item.customFields.project-hero.placement',
                  ) || 'center'}`,

                  justifyContent: 'center',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                },
                blocks: {
                  default: [
                    {
                      component: 'Text',
                      condition:
                        get('Project.item.customFields.project-hero.title') ||
                        get('Project.item.customFields.project-hero.content'),
                      props: {
                        textAlign: 'center',
                        style: 'margin-bottom: 1rem',
                      },
                      data: {
                        content: getCoverText(
                          get('Project.item.customFields.project-hero.title'),
                          get('Project.item.customFields.project-hero.content'),
                        ),
                      },
                    },
                    {
                      component: 'Flex',
                      condition: (
                        get('Project.item.customFields.project-hero-buttons') || []
                      ).filter(f => {
                        return f.content && f.type
                      }).length,

                      props: {
                        class: 'project-hero__buttons',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        gap: '1rem',
                      },
                      blocks: {
                        default: [
                          ...(get('Project.item.customFields.project-hero-buttons') || [])
                            .filter(button => {
                              if (button?.type === 'brochure') return
                              return Boolean(button.content)
                            })
                            .map(button => {
                              return {
                                component: 'Button',
                                data: {
                                  label: button.content,
                                  to:
                                    button.type === 'lead'
                                      ? { name: 'Contact' }
                                      : getLocation(button.type),
                                  href: button['custom-url'],
                                  theme: button.type === 'lead' ? 'secondary' : 'primary',
                                  icon: button.type === 'lead' ? ['fas', 'arrow-right'] : '',

                                  tag: 'router-link',
                                },
                              }
                            }),

                          {
                            component: 'Brochure',
                            condition:
                              (get('Project.item.media.brochure') || []).length &&
                              getValueFromArray(
                                get('Project.item.customFields.project-hero-buttons'),
                                'type',
                                'brochure',
                              ).type,
                            data: get('Project.item.media.brochure'),
                            props: {
                              style: 'padding-top: auto;',
                              placement: 'top',
                              label: getValueFromArray(
                                get('Project.item.customFields.project-hero-buttons'),
                                'type',
                                'brochure',
                              ).content,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            bottom: [
              {
                component: 'Stats',
                condition: showStats,
                data: {
                  content: get('Project.item.customFields.key-value'),
                },
                variant: 'split',
                props: {
                  class: 'stats',
                  padding: '1rem',
                  theme: 'secondary',
                  transform: 'vertical',
                  width: 'medium',
                },
              },
            ],
          },
        },
      },
    },

    {
      component: 'Section',
      condition:
        get('Project.item.customFields.icons.0.icon.url') ||
        get('Project.item.customFields.icons.0.content'),
      props: {
        width: 'medium',
        class: 'icons-section',
        theme: get('Project.item.customFields.icons-settings.theme'),
      },
      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('Project.item.customFields.icons-settings.columns')) || 4,
              iconBackgroundColor: get(
                'Project.item.customFields.icons-settings.show-icon-background-color',
              )
                ? get('Project.item.customFields.icons-settings.icon-background-color')
                : 'inherit',
              borderTheme: get('Project.item.customFields.icons-settings.border-theme'),
              placement: get('Project.item.customFields.icons-settings.placement'),
              iconBorder: get('Project.item.customFields.icons-settings.icon-border'),
              boldContent: true,
            },
            data: {
              content: get('Project.item.customFields.icons'),
            },
            variant: get('Project.item.customFields.icons-settings.variant') || 'underline',
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: () => {
        if (!this.item) return false
        if (get('Project.item.customFields.project-settings.hide-openhouses')) return false
        if (get('Project.item.openhouses.length')) return true
        return !get('Root.item.metadata.siteSettings.hideNoOpenhouse')
      },
      blocks: {
        default: {
          component: 'Openhouse',
          data: {
            content: get('Project.item.openhouses'),
          },
        },
      },
    },

    {
      component: 'Section',
      props: {
        id: 'link-grid',
      },
      blocks: {
        default: {
          component: 'LinkGrid',
          props: {
            items: getLinkGridItems(),
          },
        },
      },
    },

    ...(get('Project.item.customFields.additional-sections') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'],
        },
      }
    }),

    {
      component: 'Section',
      condition: get('Project.item.media.gallery.length'),
      props: {
        id: 'gallery',
        class: 'section-gallery',
        width: 'large',
      },
      blocks: {
        default: [
          {
            component: 'Gallery',
            data: {
              content: (get('Project.item.media.gallery') || []).slice(0, 4),
            },
            props: {
              columns: 4,
            },
          },
          {
            component: 'Flex',
            props: {
              justifyContent: 'center',
              style: 'padding-block: 2rem; font-size: 1.25em',
            },
            blocks: {
              default: [
                {
                  component: 'Link',
                  props: {
                    label: 'Se flere bilder',
                    tag: 'router-link',
                    target: 'self',
                  },
                  data: {
                    href: '/galleri',
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.media.attachments.length'),
      props: {
        id: 'attachments',
      },
      blocks: {
        default: [
          {
            component: 'Attachments',
            data: get('Project.item.media.attachments'),
          },
        ],
      },
    },

    {
      component: 'Map',
      condition: get('Project.item.address.location.coordinates'),
      props: {
        aspectRatio: '3/1',
      },
      data: {
        coordinates: get('Project.item.address.location.coordinates'),
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-contact',
        class: 'section-contact',
        style: 'padding-block: 6rem',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'lead',
          },
          {
            component: 'Grid',
            props: {
              gap: '2rem 8rem',
              columns: '1:2',
            },
            blocks: {
              default: [
                {
                  component: 'Flex',
                  props: {
                    flexDirection: 'column',
                    gap: '3rem',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Openhouse',
                        props: {
                          class: 'project-openhouse',
                        },
                        data: {
                          content: get('Project.item.openhouses'),
                        },
                        condition: () => {
                          if (!this.item) return false
                          if (get('Project.item.openhouses.length')) return true
                          return !get('Root.item.metadata.siteSettings.hideNoOpenhouse')
                        },
                      },
                      {
                        component: 'ContactList',
                        data: {
                          contacts: get('Project.item.roles.salesman'),
                        },
                        props: {
                          avatarAspectRatio: '1/1',
                          showLogo: true,
                          contactDetailStyle: 'icon',
                        },
                        variant: 'horizontal',
                      },
                    ],
                  },
                },

                {
                  component: 'Lead',
                  props: {
                    options: {
                      placeholders: {
                        'contact.name': this.$t('name'),
                        'contact.email': this.$t('email'),
                        'contact.phone': this.$t('phone'),
                        comment: this.$tc('message', 1),
                      },
                      // fields: ['!title'],
                    },
                  },
                  data: {
                    project: get('Project.item.id'),
                    reference: { onModel: 'Project', ref: get('Project.item.id') },
                  },
                  blocks: {
                    top: [
                      {
                        component: 'Text',
                        data: {
                          content: get('Project.item.customFields.lead-top-content'),
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    Cta(get),
  ]
}
