<template>
  <footer class="footer">
    <div class="container">
      <div v-if="companyInformation.length" class="footer__company-information">
        <div v-for="company in companyInformation" class="footer__company-information-item">
          <img v-if="company.logo" :src="company.logo.url" alt="" />
          <div v-html="company.content"></div>
        </div>
      </div>
      <div>
        <router-link to="/" @click.native="scrollToTop">
          <img :src="logo" class="footer__logo" :alt="item.name" />
        </router-link>
        <div class="footer__menu">
          <template v-for="(item, index) in navigation">
            <ActionComponent
              v-if="$path('action.name', item) !== $path('name', $route)"
              class="footer__menu-action"
              :class="{ 'footer__menu-action--empty': !item.action }"
              v-bind="item"
              :key="index"
            />
            <template v-if="item.items && item.items.length">
              <ActionComponent
                v-for="(nested, ni) in item.items"
                class="footer__menu-action footer__menu-action--indent"
                v-bind="nested"
                :key="index + '_' + ni"
              />
            </template>
          </template>
        </div>
        <div class="footer__policy">
          <router-link :to="`/api/legal/privacy/project/${item.id}`" target="_blank">
            <FontAwesomeIcon :icon="['far', 'lock-alt']" size="1x" class="footer__icon" />{{
              $t('privacyPolicy')
            }}
            /
            {{ $t('cookiePolicy') }}
          </router-link>
          <router-link v-if="disclaimer" :to="disclaimer.slug">
            <FontAwesomeIcon :icon="['far', 'file-alt']" class="footer__icon" size="1x" />{{
              disclaimer.title
            }}
          </router-link>
          <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
        </div>
      </div>
    </div>
    <div class="footer__container-bottom">
      <span>
        {{ $t('pageMadeBy') }}
        <a :href="`https://www.kvass.no?referrer=${currentUrl}`">Kvass</a>
      </span>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import ActionComponent from 'vue-elder-navigation/src/action'
import API from '@/api'
import { GetCustomField, ScrollToAnchor } from '@/utils'

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),

    address() {
      return this.item.address
    },

    disclaimer() {
      if (!this.item.posts) return

      const disclaimer = this.item.posts.find(p => p.customFieldsConfig[0].source === 'disclaimer')

      return disclaimer
    },
    currentUrl() {
      return window.location.href
    },

    logo() {
      return API.logo
    },

    companyInformation() {
      return (this.$path('customFields.company-information', this.item) || []).filter(
        ({ content, logo }) => content || logo,
      )
    },
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    navigate() {
      ScrollToAnchor('lead')
    },
  },
  components: {
    ActionComponent,
  },
}
</script>

<style lang="scss">
.footer {
  --space: 5.75rem;
  --item-gap: 1.1rem;
  background-color: var(--primary);
  color: var(--primary-contrast);

  .container {
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 2.25rem var(--space);

    @include respond-below('tablet') {
      grid-template-columns: 1fr;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  .elder__navigation-component {
    color: var(--primary-contrast);
    line-height: 20px;
    text-decoration: none;
    background: none;

    &:hover {
      text-underline-offset: 5px;
      text-decoration: underline;
    }
  }

  a {
    color: var(--primary-contrast);
    line-height: 20px;
    text-decoration: none;
    padding: 0;
    margin: 0;

    &:hover {
      text-underline-offset: 5px;
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }

  &__container {
    &-bottom {
      font-size: 0.875rem;
      line-height: 24px;
      color: white;
      padding: 10px;
      text-align: center;
      width: 100%;
      background-color: var(--secondary);

      a {
        text-underline-offset: 3px;
        color: white;
        text-decoration: underline;

        &:hover {
          text-underline-offset: 3px;
        }
      }
    }
  }

  &__logo {
    width: 115px;
    margin-bottom: 1rem;

    @include respond-below('phone') {
      order: 1;
    }
  }

  &__icon {
    color: var(--secondary);
    margin-right: 10px;
  }

  &__policy {
    display: grid;
    gap: var(--item-gap);
    grid-template-columns: 1fr;

    @include respond-below('phone') {
      order: 2;
    }
  }

  &__menu {
    column-count: 1;
    column-gap: var(--space);

    @include respond-below('phone') {
      column-count: unset;
      display: flex;
      flex-direction: column;
      order: 2;
    }

    .elder__navigation-component-icon-right {
      display: none;
    }

    a {
      margin-bottom: var(--item-gap);
    }

    &-action {
      line-height: 20px;
      margin: 0;
      padding: 0;
      background-color: transparent;
      color: inherit;
      font-weight: inherit;
      align-items: flex-start;
      margin-bottom: var(--item-gap);

      & div:hover {
        text-underline-offset: 5px;
        text-decoration: underline !important;
        text-decoration-color: var(--primary-contrast);

        cursor: pointer;
      }

      &--indent {
        margin-left: 0.5rem;
      }
    }
  }

  &__company-information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;

    @include respond-below('tablet') {
      grid-template-columns: 1fr;
      gap: 2.5rem;
    }

    &-item {
      // font-size: .8em;

      img {
        height: 40px;
      }
    }
  }
}
</style>
