<template>
  <div
    v-if="value.length"
    class="kvass-image-text"
    :class="'kvass-image-text-render-direction--' + startDirection"
  >
    <div
      v-for="(item, index) in value"
      :key="index"
      class="kvass-image-text__item"
      :class="[
        `kvass-image-text__item-theme--${item.theme || 'none'}`,
        {
          'kvass-image-text__item--text-over': (item['image-settings'] || []).includes('text-over'),
          'kvass-image-text__item--no-image-padding':
            !(item['image-settings'] || []).includes('center-content') || false,
        },
      ]"
    >
      <div
        class="kvass-image-text__images"
        :class="{ 'kvass-image-text__images--grid': item.images.length > 1 }"
      >
        <ImageComponent
          v-for="(image, index) in item.images"
          :key="index + 'image'"
          class="kvass-image-text__image"
          :style="index + 1 === item.images.length && item.images.length % 2 == 1 ? style : {}"
          :class="{
            'kvass-image-text__image--contain': (item['image-settings'] || []).includes('contain'),
            'kvass-image-text__image--frame': (item['image-settings'] || []).includes('frame'),
            'kvass-image-text__image--round-edges': (item['image-settings'] || []).includes(
              'round-edges',
            ),
          }"
          :value="{
            url: $path('url', image) || image,
            name: image.title,
            description: $path('image.description', item),
          }"
          v-bind="$attrs"
        />
      </div>

      <div
        class="kvass-image-text__content"
        :class="{
          'kvass-image-text__content--text-over': (item['image-settings'] || []).includes(
            'text-over',
          ),
        }"
      >
        <div class="kvass-image-text__kicker">{{ item.kicker }}</div>
        <div class="kvass-image-text__description" v-html="item.description" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageComponent from '@kvass/media-render/Types/Image'
export default {
  props: {
    data: Object,
    startDirection: {
      type: String,
      default: 'odd',
      enums: ['odd', 'even'],
    },
  },
  computed: {
    value() {
      return this.$path('content', this.data) || []
    },

    style() {
      return {
        'grid-column-end': 'span 2',
      }
    },
  },
  components: {
    ImageComponent,
  },
}
</script>

<style lang="scss">
$textOverMargin: 11rem;
.kpb-section--width-full.section-image-text {
  $maxWidth: 600px;
  .kvass-image-text__item:not(.kvass-image-text__item--text-over) {
    .kvass-image-text__content {
      max-width: $maxWidth;
    }
  }
  .kvass-image-text__item--text-over {
    .kvass-image-text__content {
      max-width: calc(#{$maxWidth} + #{$textOverMargin});
    }
  }
}
.kvass-image-text {
  $breakAt: 850px;

  column-gap: 4rem;
  padding: 0;

  &-render-direction {
    &--odd {
      .kvass-image-text__item {
        @media (min-width: $breakAt + 1) {
          &:nth-child(odd) {
            direction: rtl;
            .kvass-image-text__content {
              justify-content: end;
              &--text-over {
                margin: 4rem calc(-#{$textOverMargin}) 1.5rem auto;
              }
            }
          }
          &:nth-child(even) {
            direction: initial !important;
            .kvass-image-text__content {
              justify-content: start;
              &--text-over {
                margin: 4rem auto 1.5rem calc(-#{$textOverMargin});
              }
            }
          }
        }
      }
    }
    &--even {
      .kvass-image-text__item {
        @media (min-width: $breakAt + 1) {
          &:nth-child(even) {
            direction: rtl;
            .kvass-image-text__content {
              justify-content: end;
              &--text-over {
                margin: 4rem calc(-#{$textOverMargin}) 1.5rem auto;
              }
            }
          }
          &:nth-child(odd) {
            direction: initial !important;
            .kvass-image-text__content {
              justify-content: start;
              &--text-over {
                margin: 4rem auto 1.5rem calc(-#{$textOverMargin});
              }
            }
          }
        }
      }
    }
  }

  &__kicker {
    text-transform: uppercase;
    font-weight: 500;
    opacity: 0.8;
    font-size: 0.9rem;
  }

  &__item {
    $space: 20rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7rem;
    align-items: center;
    margin: 0 !important;
    padding: 6rem;

    @media (max-width: $breakAt) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      padding: 2rem 1rem 2rem 1rem;
      overflow: hidden;
    }

    &:nth-child(1).kvass-image-text__item-theme--none {
      padding-top: 0;
    }

    * {
      direction: ltr;
      text-align: left;
    }

    &--no-image-padding {
      padding: 6rem 0rem;
    }
    &-theme {
      &--primary {
        background-color: var(--primary);
        color: var(--primary-contrast);

        .kvass-image-text__image {
          &--frame {
            img {
              border: 3px solid var(--secondary);
            }
          }
        }
      }
      &--secondary {
        background-color: var(--secondary);
        color: var(--secondary-contrast);

        .kvass-image-text__image {
          &--frame {
            img {
              border: 3px solid var(--primary);
            }
          }
        }
      }
      &--none {
        @media (max-width: $breakAt) {
          padding: 0 0 2rem 0;
        }
        .kvass-image-text__image {
          &--frame {
            img {
              border: 3px solid var(--primary);
            }
          }
        }
      }
    }
  }

  &__images {
    @media (min-width: $breakAt) {
      .kvass-media-render-image {
        img {
          min-height: 500px;
        }
      }
    }
    &--grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      .kvass-media-render-image {
        img {
          min-height: initial;
        }
      }
    }
  }

  &__image {
    &--contain {
      img {
        object-fit: contain !important;
        height: initial;
      }
    }
    &--frame {
      img {
        border: 3px solid white;
        padding: 0.4rem;
      }
    }
    &--round-edges {
      $radius: 3rem;
      img {
        border-radius: $radius;
      }

      .kvass-media-render-image__caption {
        border-radius: $radius;
      }

      @media (max-width: $breakAt) {
        img {
          border-radius: calc(#{$radius} - 1rem);
        }
        .kvass-media-render-image__caption {
          border-radius: calc(#{$radius} - 1rem);
        }
      }
    }
  }

  &__content {
    text-align: right;

    h2 {
      word-wrap: break-word;
    }

    &--text-over {
      background: white;
      padding: 2rem;
      position: relative;
      color: initial;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
    padding: 0rem 1rem;
  }

  .kvass-media-render-image__caption {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    padding: 0.8em 2rem;
  }
}
</style>
