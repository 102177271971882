<template>
  <DropdownComponent class="brochure" :placement="placement">
    <ButtonComponent
      :theme="theme"
      :icon="['far', 'arrow-down']"
      :label="actionLabel"
      @click="hasMany ? () => {} : download(data[0])"
    />
    <template #dropdown>
      <template v-if="hasMany">
        <ButtonComponent
          v-for="(item, index) in data"
          :key="index"
          :icon="['fad', 'file-pdf']"
          :label="item.name"
          @click="download(item)"
        />
      </template>
    </template>
  </DropdownComponent>
</template>

<script>
import { Capitalize } from '@/utils'

import { DropdownComponent } from 'vue-elder-dropdown'
import { EventBus as AssetBus } from '@kvass/asset-manager'

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    placement: {
      type: String,
      default: 'bottom end',
    },
    theme: {
      type: String,
      default: 'primary',
    },
    label: String,
  },
  computed: {
    hasMany() {
      return this.data.length > 1
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    actionLabel() {
      return this.label || this.$t('assetManagerTitleBrochure' + Capitalize(this.brochureStrategy))
    },
  },
  methods: {
    download(item) {
      AssetBus.emit('download', {
        type: 'brochure',
        strategy: this.brochureStrategy,
        asset: item,
      })
    },
  },
  components: {
    DropdownComponent,
  },
}
</script>

<style lang="scss">
.brochure {
  color: $dark;
}
</style>
