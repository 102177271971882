<template>
  <div v-if="data.length" class="attachments">
    <h2 class="attachments__title">Dokumenter</h2>
    <div class="attachments__items">
      <a
        :href="attachment.url"
        class="attachments__item"
        v-for="attachment in data"
        :key="attachment.key"
        @click.prevent="download(attachment)"
      >
        <FontAwesomeIcon :icon="getIcon(attachment)" size="3x" />
        <div class="attachments__item-label">
          {{ attachment.name }}
        </div>
      </a>
    </div>
    <div id="attachments" class="scroll-anchor"></div>
  </div>
</template>

<script>
import { DownloadUrl } from '@/utils'

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getIcon(item) {
      return [
        { pattern: /application\/pdf/, icon: ['fad', 'file-pdf'] },
        { pattern: /image\/.+/, icon: ['fad', 'file-image'] },
        { pattern: /.+/, icon: ['fad', 'file-alt'] },
      ].find(e => e.pattern.test(item.type)).icon
    },
    download(item) {
      if (window.Kvass) {
        Kvass.emit('track', {
          event: 'download',
          data: { type: 'attachment', name: item.name, url: item.url },
        })
      }
      DownloadUrl(item.url, item.name)
    },
  },
}
</script>

<style lang="scss">
.attachments {
  position: relative;

  &__title {
    margin-bottom: 2rem;
  }

  &__items {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }

  &__item {
    padding: 3em;
    border: 1px solid var(--primary);
    text-align: center;

    .svg-inline--fa .fa-secondary {
      fill: var(--custom-primary-contrast);
    }

    &-label {
      opacity: 0.75;
      margin-top: 0.7em;
      word-break: break-all;
    }
  }
}
</style>
