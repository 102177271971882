var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"route",class:[
    'route--' + _vm.view.toLowerCase(),
    _vm.isOnTop ? 'page--is-top' : 'page--is-scrolled',
    {
      [`route--${_vm.view.toLowerCase()}-${_vm.slug}`]: _vm.view.toLowerCase() === 'post',
    },
  ],style:(`--custom-background-color: ${_vm.customColor}; --custom-body-color: ${_vm.customBodyColor}`),attrs:{"id":"app"}},[_c('div',{staticClass:"transition"},[_c('img',{staticClass:"transition__img",attrs:{"src":_vm.logo.src || _vm.logo}})]),(_vm.$route.meta.navigation !== false)?_c('div',{staticClass:"nav",class:[
      'nav--' + _vm.view.toLowerCase(),
      {
        ['nav--expanded']: _vm.isExpanded,
        [`nav--${_vm.view.toLowerCase()}-${_vm.slug}`]: _vm.view.toLowerCase() === 'post',
      },
    ]},[_c('div',{staticClass:"container"},[_c('NavigationComponent',{attrs:{"logo":_vm.logo,"items":_vm.items,"title":_vm.projectName,"padding":"20px 0","breakAt":_vm.breakAt,"action":_vm.mainAction || _vm.redirect(['Project', 'Residential'], { scrollTo: 0 }),"isOpen":_vm.isExpanded},on:{"update:isOpen":function($event){_vm.isExpanded=$event},"update:is-open":function($event){_vm.isExpanded=$event}}})],1)]):_vm._e(),_c('transition',{attrs:{"mode":"out-in","name":"route"}},[_c('router-view')],1),(_vm.$route.meta.footer !== false)?_c('FooterComponent',{attrs:{"navigation":_vm.items.filter(i => i.class !== 'elder__navigation-component--policy'),"cookie-manager":_vm.CM}}):_vm._e(),_c('AssetComponent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }