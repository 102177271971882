export default function (get) {
	return {
		component: 'Section',
		condition:
			get('Root.item.customFields.cta.content') || get('Root.item.customFields.cta.button'),
		props: {
			class: 'cta-bottom',
			style: `padding-block: 3.5rem; ${get('Root.item.customFields.cta.background.url') ? `background-image: url('${get('Root.item.customFields.cta.background.url')}')` : ''}`,
		},

		blocks: {
			default: [
				{
					component: 'Flex',
					props: {
						flexDirection: 'column',
						alignItems: 'center',
						class: 'cta-bottom__content',
					},

					blocks: {
						default: [
							{
								component: 'Text',
								data: {
									content: get('Root.item.customFields.cta.content'),
								},
								props: {
									textAlign: 'center',
									style: `color: ${get('Root.item.customFields.cta.color') === 'light' ? 'white' : 'black'}`
								},
							},
							{
								component: 'Flex',
								condition: (
									get('Root.item.customFields.cta-buttons') || []
								).filter(f => {
									return f.content && f.type
								}).length,

								props: {
									alignItems: 'center',
									justifyContent: 'center',
									flexWrap: 'wrap',
									gap: '1rem',
								},
								blocks: {
									default: [
										...(get('Root.item.customFields.cta-buttons') || [])
											.filter(button => {
												if (button?.type === 'brochure') return
												return Boolean(button.content)
											})
											.map(button => {
												return {
													component: 'Button',
													data: {
														label: button.content,
														to: button.type === 'lead'
															? { name: 'Contact' }
															: getLocation(button.type),
														href: button['custom-url'],
														theme: button.type === 'lead' ? 'secondary' : 'primary',
														icon: button.type === 'lead' ? ['fas', 'arrow-right'] : '',
														tag: 'router-link',
													},
												}
											}),
										...(get('Root.item.customFields.cta-buttons') || [])
											.filter(button => button && button.type === 'brochure' && Boolean(button.content))
											.map(button => {
												return {
													component: 'Brochure',
													data: get('Root.item.media.brochure') || [],
													props: {
														label: button.content,
														theme: button.type === 'lead' ? 'secondary' : 'primary',
													}
												}
											})
									],
								},
							},
						],
					},
				},
			],
		},
	}
}