<template>
  <ul class="link-grid">
    <li v-for="item in items" :key="item.text + item.url" class="link-grid__item">
      <component
        v-if="item.url"
        :is="item.url.startsWith('http') ? 'a' : 'router-link'"
        :to="item.url"
        :href="item.url"
      >
        <img
          v-if="item.image && item.image.url"
          :src="item.image.url"
          alt=""
          class="link-grid__item-image"
        />
        <p class="link-grid__item-text">
          <span>{{ item.text }}</span>
          <FontAwesomeIcon :icon="['far', 'arrow-right']" />
        </p>
      </component>
    </li>
  </ul>
</template>

<script>
import BrowserApiMixin from '@/mixins/browser-api'
export default {
  mixins: [BrowserApiMixin('link-grid')],
  props: {
    items: {
      type: Array,
      validator: items => items.every(item => ['text', 'url', 'image'].every(key => key in item)),
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.link-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2em;
  padding: 0;

  &__item {
    list-style: none;
    background-color: #f5f5f5;
    display: grid;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(to top, rgba(black, 0.5), transparent 25%);
      position: absolute;
      pointer-events: none;
    }

    & > a:is(:hover, :focus-visible) .link-grid__item-text {
      text-decoration: underline;
    }

    &-image {
      width: 100%;
      height: 100%;
      aspect-ratio: 6/5;
      object-fit: cover;
    }

    &-text {
      position: absolute;
      bottom: 1.2rem;
      left: 1.2rem;
      margin: 0;
      font-size: 1.18rem;
      line-height: 1;
      color: white;
      z-index: 100;
      font-weight: bold;
      text-shadow: 0 2px 4px rgba(black, 0.5);
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
}
</style>
