var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value.length)?_c('div',{staticClass:"kvass-image-text",class:'kvass-image-text-render-direction--' + _vm.startDirection},_vm._l((_vm.value),function(item,index){return _c('div',{key:index,staticClass:"kvass-image-text__item",class:[
      `kvass-image-text__item-theme--${item.theme || 'none'}`,
      {
        'kvass-image-text__item--text-over': (item['image-settings'] || []).includes('text-over'),
        'kvass-image-text__item--no-image-padding':
          !(item['image-settings'] || []).includes('center-content') || false,
      },
    ]},[_c('div',{staticClass:"kvass-image-text__images",class:{ 'kvass-image-text__images--grid': item.images.length > 1 }},_vm._l((item.images),function(image,index){return _c('ImageComponent',_vm._b({key:index + 'image',staticClass:"kvass-image-text__image",class:{
          'kvass-image-text__image--contain': (item['image-settings'] || []).includes('contain'),
          'kvass-image-text__image--frame': (item['image-settings'] || []).includes('frame'),
          'kvass-image-text__image--round-edges': (item['image-settings'] || []).includes(
            'round-edges',
          ),
        },style:(index + 1 === item.images.length && item.images.length % 2 == 1 ? _vm.style : {}),attrs:{"value":{
          url: _vm.$path('url', image) || image,
          name: image.title,
          description: _vm.$path('image.description', item),
        }}},'ImageComponent',_vm.$attrs,false))}),1),_c('div',{staticClass:"kvass-image-text__content",class:{
        'kvass-image-text__content--text-over': (item['image-settings'] || []).includes(
          'text-over',
        ),
      }},[_c('div',{staticClass:"kvass-image-text__kicker"},[_vm._v(_vm._s(item.kicker))]),_c('div',{staticClass:"kvass-image-text__description",domProps:{"innerHTML":_vm._s(item.description)}})])])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }