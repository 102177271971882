<template>
  <ResidentialTemplates
    v-if="$path('references', value)"
    :value="value"
    class="residential-templates"
  />
</template>

<script>
import { Items as ResidentialTemplates } from '@kvass/template-residential-template'

export default {
  props: {
    data: Object,
  },
  computed: {
    value() {
      return this.$path('content', this.data) || []
    },
  },

  components: {
    ResidentialTemplates,
  },
}
</script>

<style lang="scss">
.residential-templates {
}
</style>
